import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { DownloadURLPipe } from './pipes/download-url.pipe';
import { GetLicensePipe } from './pipes/get-license.pipe';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRippleModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';

import { GetTrainerPipe } from './pipes/get-trainer.pipe';
import { DesiredAppointmentPipe } from './pipes/desired-appointment.pipe';
import { TrainerPipe } from './pipes/trainer.pipe';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { PlacesAutocompleteComponent } from './components/places-autocomplete/places-autocomplete.component';
import { ReferenceSelectComponent } from './components/reference-select/reference-select.component';
import { PasswordResetDialogComponent } from './components/password-reset-dialog/password-reset-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { FilterChallengesPipe } from './pipes/filter-challenges.pipe';
import { UserPipe } from './pipes/user.pipe';
import { FeedbackPipe } from './pipes/feedback.pipe';

import { DpDatePickerModule } from 'ng2-date-picker';
import { FilterExercisesPipe } from './pipes/filter-exercises.pipe';
import { EditUserEmailDialogComponent } from './components/edit-user-email-dialog/edit-user-email-dialog.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { IconPipe } from './pipes/icon.pipe';
import { IngredientsPipe } from './pipes/ingredients.pipe';
import { RecipePreviewDialogComponent } from './components/recipe-preview-dialog/recipe-preview-dialog.component';
import { TimeSecondsPipe } from './pipes/time-seconds.pipe';
import { ExerciseSetsEditComponent } from './components/exercise-sets-edit/exercise-sets-edit.component';
import { MealTypePipe } from './pipes/meal-type.pipe';
import { NutritionPlanValuesComponent } from './components/nutrition-plan-values/nutrition-plan-values.component';
import { CarouselGroupComponent } from './ui-components/carousel-group/carousel-group.component';
import { CarouselItemComponent } from './ui-components/carousel-item/carousel-item.component';
import { ObserversModule } from '@angular/cdk/observers';
import { ProgressChartComponent } from './components/progress-chart/progress-chart.component';
import { MediaObjectFolderPipe } from './pipes/media-object-folder.pipe';
import { ChallengeCountPipe } from './pipes/challenge-count.pipe';
import { NutritionPlanCountPipe } from './pipes/nutrition-plan-count.pipe';
import { DeviceRecordCountPipe } from './pipes/device-record-count.pipe';
import { FilterRecipesPipe } from './pipes/filter-recipes.pipe';
import { FilterFormElementsPipe } from './pipes/filter-form-elements.pipe';
import { FormElementTypePipe } from './pipes/form-element-type.pipe';
import { FilterFormContainersPipe } from './pipes/filter-form-containers.pipe';
import { UnreadCustomerMessageCountPipe } from './pipes/unread-customer-message-count.pipe';

@NgModule({
  declarations: [
    // Pipes
    DownloadURLPipe,
    GetLicensePipe,
    GetTrainerPipe,
    DesiredAppointmentPipe,
    TrainerPipe,
    FilterChallengesPipe,
    UserPipe,
    FeedbackPipe,
    FilterExercisesPipe,
    IconPipe,
    IngredientsPipe,
    TimeSecondsPipe,
    MealTypePipe,
    MediaObjectFolderPipe,
    ChallengeCountPipe,
    NutritionPlanCountPipe,
    DeviceRecordCountPipe,
    FilterRecipesPipe,
    FilterFormElementsPipe,
    FormElementTypePipe,
    FilterFormContainersPipe,
    UnreadCustomerMessageCountPipe,

    // Components
    FileUploadComponent,
    PlacesAutocompleteComponent,
    ReferenceSelectComponent,
    PasswordResetDialogComponent,
    EditUserEmailDialogComponent,
    LoadingModalComponent,
    RecipePreviewDialogComponent,
    ExerciseSetsEditComponent,
    NutritionPlanValuesComponent,
    CarouselGroupComponent,
    CarouselItemComponent,
    ProgressChartComponent,
  ],
  providers: [
    // Pipes
    GetTrainerPipe,
    DatePipe,
    FilterExercisesPipe,
    IngredientsPipe,
  ],
  imports: [
    // Angular
    CommonModule,

    // Forms
    ReactiveFormsModule,

    // Material
    MatButtonModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressBarModule,
    MatIconModule,
    MatSelectModule,

    // CDK
    ObserversModule,

    // Other
    DpDatePickerModule,
    ImageCropperModule,
  ],
  exports: [
    // Angular
    CommonModule,

    // Forms
    ReactiveFormsModule,
    FormsModule,

    // CDK
    DragDropModule,

    // Material
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatTableModule,
    MatSelectModule,
    MatTabsModule,
    MatDialogModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    LayoutModule,
    MatSidenavModule,
    MatRippleModule,
    MatStepperModule,
    MatRadioModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatBadgeModule,

    // Components
    FileUploadComponent,
    PlacesAutocompleteComponent,
    ReferenceSelectComponent,
    PasswordResetDialogComponent,
    EditUserEmailDialogComponent,
    LoadingModalComponent,
    RecipePreviewDialogComponent,
    ExerciseSetsEditComponent,
    NutritionPlanValuesComponent,
    ProgressChartComponent,

    // UI Components
    CarouselGroupComponent,
    CarouselItemComponent,

    // Pipes
    DownloadURLPipe,
    GetLicensePipe,
    GetTrainerPipe,
    DesiredAppointmentPipe,
    TrainerPipe,
    FilterChallengesPipe,
    UserPipe,
    FeedbackPipe,
    FilterExercisesPipe,
    IconPipe,
    IngredientsPipe,
    TimeSecondsPipe,
    MealTypePipe,
    MediaObjectFolderPipe,
    ChallengeCountPipe,
    NutritionPlanCountPipe,
    DeviceRecordCountPipe,
    FilterRecipesPipe,
    FilterFormElementsPipe,
    FormElementTypePipe,
    FilterFormContainersPipe,
    UnreadCustomerMessageCountPipe,

    // Other
    DpDatePickerModule,
    ImageCropperModule,
  ],
})
export class SharedModule {}
