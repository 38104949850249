import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { MessageService } from 'src/app/core/services/message.service';
import { Customer } from '../models/customer';

@Pipe({
  name: 'unreadCustomerMessageCount',
})
export class UnreadCustomerMessageCountPipe implements PipeTransform {
  constructor(private messageSvc: MessageService) {}

  transform(
    customer: Customer,
    updates: 'single' | 'live' = 'single'
  ): Promise<number> | Observable<number> {
    if (updates === 'single') {
      return this.messageSvc.getUnreadMessageCountOfTrainerSingle(customer);
    } else {
      return this.messageSvc.getUnreadMessageCountOfTrainerLive(customer);
    }
  }
}
